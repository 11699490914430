import request from "../utils/request"



export function getAppList(data) {
    return request({
        url: '/admin/app/list',
        method: 'post',
        data
    })
}

export function getManufacturerAppList(data) {
    return request({
        url: '/admin/manufacturer/app/list',
        method: 'post',
        data
    })
}

export function getManufacturerList(data) {
    return request({
        url: '/admin/manufacturer/list',
        method: 'post',
        data
    })
}

export function getManufacturerSimpleList(data) {
    return request({
        url: '/admin/manufacturer/simple/list',
        method: 'post',
        data
    })
}

export function getManufacturerDeveloperList(data) {
    return request({
        url: '/admin/manufacturer/developer/list',
        method: 'post',
        data
    })
}
export function getManufacturerDeveloper(data) {
    return request({
        url: '/admin/manufacturer/developer',
        method: 'post',
        data
    })
}
export function updateManufacturer(data) {
    return request({
        url: '/admin/manufacturer/update',
        method: 'post',
        data
    })
}
export function addCollection(data) {
    return request({
        url: '/admin/app/collection/add',
        method: 'post',
        data
    })
}
export function cancelCollection(data) {
    return request({
        url: '/admin/app/collection/cancel',
        method: 'post',
        data
    })
}